// src/components/ValidatedInput.tsx
import React from 'react'
import { FormControl, FormLabel, Input, HStack } from '@chakra-ui/react'
import { keepNumberInRange } from '../utils/dataValidation'

interface ValidatedInputProps {
  label: string
  value: number | null
  min: number
  max: number
  isRequired?: boolean
  onChange: (value: number | null) => void
  width?: string | number
  inputWidth?: string | number
}

const ValidatedInput: React.FC<ValidatedInputProps> = ({
  label,
  value = null,
  min,
  max,
  isRequired = true,
  onChange,
  width = '100%',
  inputWidth = '50%',
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    if (inputValue === '') {
      onChange(null)
    } else {
      const validatedValue = keepNumberInRange(inputValue, min, max)
      onChange(validatedValue)
    }
  }

  return (
    <FormControl isRequired={isRequired} width={width}>
      <HStack>
        <FormLabel flex="1">{label}</FormLabel>
        <Input
          type="number"
          value={value ?? ''}
          onChange={handleChange}
          width={inputWidth}
        />
      </HStack>
    </FormControl>
  )
}

export default ValidatedInput
