import {
  Box,
  Button,
  Checkbox,
  Heading,
  List,
  ListItem,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { chain, keys, map, orderBy, startCase } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useConfigList from "../../hooks/useConfigList";
import useFirestoreUser from "../../hooks/useFirestoreUser";
import useIsSuperAdmin from "../../hooks/useIsSuperAdmin";
import useReports from "../../hooks/useReports";
import useStagesCount from "../../hooks/useStagesCount";
import { alsfrs } from "../../questionnaires/alsfrs";
import { alssq } from "../../questionnaires/alssq";
import useStore, { Reports, waterTypes } from "../../store/useStore";
import FirestoreUser, { diagnosisTitles } from "../../types/FirestoreUser";
import { maxQuestionnaireScore } from "../../types/Questionnaire";
import {
  Regimen,
  amountUnits,
  durationUnits,
  frequencyUnits,
  methods,
  percentages,
} from "../../types/Regimen";
import {
  StoolQuestionnaire,
  giSymptoms,
  stoolTypes2,
  stoolVolumes,
  urineColors,
} from "../../types/StoolQuestionnaire";
import formatMoment from "../../utils/formatMoment";
import { formatMonth } from "../../utils/formatMonth";
import { FamilySupportTable } from "../FamilySupport";
import { MedicalSupportTable } from "../MedicalSupport";
import Bootcamps from "../Stages/Bootcamps";
import LiveSessions from "../Stages/LiveSessions";
import StagesTable from "../Stages/StagesTable";
import { VitalStatsTable } from "../VitalStats";
import PANAS from "./Panas";

export const PrintContent = ({
  uid,
  user,
  reports,
  regimens,
}: {
  uid: string;
  user: FirestoreUser;
  reports: Reports;
  regimens: Regimen[];
}) => {
  const [state, setState] = useState<{ [id: string]: boolean }>({
    medical: true,
  });

  const MyCheckbox = ({ id, title }: { id: string; title: string }) => {
    return (
      <Checkbox
        isChecked={state[id]}
        onChange={(e) => {
          setState({
            ...state,
            [id]: e.target.checked,
          });
        }}
      >
        {title}
      </Checkbox>
    );
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={0}>
        <Heading>Print Records</Heading>
        {user && <Subheading user={user} uid={uid} />}
      </Stack>
      <Box p={2} borderWidth={1} borderColor="gray.300" rounded="md">
        <Stack spacing={0}>
          <Text fontSize="sm">
            Which types of records do you want to include in this report?
          </Text>
          <MyCheckbox id="medical" title="Medical" />
          <MyCheckbox id="support" title="Support Team" />
          <MyCheckbox
            id="clinicalObservations"
            title="Journal - Clinical Observations"
          />
          <MyCheckbox
            id="healingAlsActivities"
            title="Healing ALS Activities"
          />
        </Stack>
      </Box>
      <Box>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print
        </Button>
      </Box>
      {state.medical && (
        <MedicalRecords reports={reports} regimens={regimens} user={user} />
      )}
      {state.clinicalObservations && <ClinicalObservations reports={reports} />}
      {state.support && <PrintSupport reports={reports} />}
      {state.healingAlsActivities && (
        <HealingALSActivities reports={reports} user={user} />
      )}
    </Stack>
  );
};

export default () => {
  const uid = useStore((s) => s.user?.uid);
  const user = useFirestoreUser();
  const reports = useReports();
  const regimens = useStore((state) => state.regimens || []);

  if (!uid || !user) return null;

  return (
    <PrintContent user={user} reports={reports} regimens={regimens} uid={uid} />
  );
};

const MedicalRecords = ({
  reports,
  regimens,
  user,
}: {
  reports: Reports;
  regimens: Regimen[];
  user: FirestoreUser;
}) => {
  // const count = useStagesCount()
  return (
    <Stack spacing={4}>
      <PrintQuestionnaire type="alsfrs" reports={reports} />
      <PrintQuestionnaire type="alssq" reports={reports} />
      <PANAS panasType="main" reports={reports} />
      <PANAS panasType="supplemental" reports={reports} />
      <PANAS panasType="modifiedPanas" reports={reports} />
      <PrintRegimens title="Diets" list="diets" regimens={regimens} />
      <Water reports={reports} />
      <PrintRegimens
        title="Supplements"
        list="supplements"
        regimens={regimens}
      />
      <PrintRegimens
        title="Medications"
        list="nonSupplementalMedications"
        regimens={regimens}
      />
      <PrintRegimens title="Protocols" list="protocols" regimens={regimens} />
      <PrintRegimens
        title="Physical Strategies"
        list="physicalStrategies"
        regimens={regimens}
      />
      <PrintRegimens
        title="Mind Strategies"
        list="mentalStrategies"
        regimens={regimens}
      />
      <Stack spacing={0}>
        <Heading size="md">Vital Statistics</Heading>
        <VitalStatsTable reports={reports} />
      </Stack>
      <Stool reports={reports} />
      <Urine reports={reports} />
    </Stack>
  );
};

const Water = ({ reports }: { reports: Reports }) => {
  return (
    <Stack>
      <Heading fontSize="md">Water Intake</Heading>
      <TableContainer>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th>Water Type</Th>
              <Th>Water Amount (oz.)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {map(reports, (report, month) => {
              const m = moment(month + "-15");
              if (!report?.waterAmount && !report?.waterType) return null;
              return (
                <Tr key={month}>
                  <Td>{m.format("MMM-YY")}</Td>
                  <Td>
                    {report?.waterType ? waterTypes[report.waterType] : "-"}
                  </Td>
                  <Td>{report?.waterAmount || "-"}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const HealingALSActivities = ({
  reports,
  user,
}: {
  reports: Reports;
  user: FirestoreUser;
}) => {
  const count = useStagesCount();

  return (
    <Stack spacing={4}>
      <Stack>
        <Heading fontSize="md">Healing ALS Group Sessions</Heading>
        <LiveSessions reports={reports} />
      </Stack>
      <Stack>
        <Heading fontSize="md">Bootcamps</Heading>
        <Bootcamps editable={false} user={user} />
      </Stack>
      <Stack>
        <Heading fontSize="md">{count} Steps To Healing</Heading>
        <StagesTable user={user} />
      </Stack>
    </Stack>
  );
};

const ClinicalObservations = ({ reports }: { reports: Reports }) => {
  return (
    <Stack>
      {map(reports, (report, id) => {
        if (
          !report?.clinicalObservations ||
          typeof report.clinicalObservations === "boolean"
        )
          return null;

        let str = report.clinicalObservations;
        while (str.includes("\n\n")) {
          str = str.replace("\n\n", "\n");
        }

        return (
          <Stack spacing={2} key={id}>
            <Heading fontSize="md">{id}</Heading>
            <Text whiteSpace="pre-line">{str}</Text>
          </Stack>
        );
      })}
    </Stack>
  );
};

const Subheading = ({ user, uid }: { user: FirestoreUser; uid: string }) => {
  const isSuperAdmin = useIsSuperAdmin();
  const isMine = useStore((s) => s.user?.uid === uid);

  return (
    <>
      <Text fontSize="sm" fontWeight="normal">
        Patient ID: #{user.patientId}
      </Text>
      <Text fontSize="sm" fontWeight="normal">
        Name: {user.profile?.firstName + " " + user.profile?.lastName}
      </Text>
      <Text fontSize="sm" fontWeight="normal">
        Email: {user.email}
      </Text>
      <Text fontSize="sm" fontWeight="normal">
        Phone: {user.profile?.phoneNumber || "N/A"}
      </Text>
      <Text fontSize="sm" fontWeight="normal">
        Diagnosis:{" "}
        {user.profile?.diagnosis
          ? diagnosisTitles[user.profile.diagnosis]
          : "N/A"}
      </Text>
      <Text fontSize="sm" fontWeight="normal">
        Birth Date:{" "}
        {!user.profile?.birthDate
          ? "N/A"
          : isSuperAdmin || isMine
          ? user.profile.birthDate
          : moment(user.profile.birthDate).format("MM/YYYY")}
      </Text>
      <Text fontSize="sm" fontWeight="normal">
        Diagnosis Date:{" "}
        {!user.profile?.diagnosisDate
          ? "N/A"
          : isSuperAdmin || isMine
          ? user.profile.diagnosisDate
          : moment(user.profile.diagnosisDate).format("MM/YYYY")}
      </Text>
      {isSuperAdmin && (
        <Link to={`/admin/users/${uid}`}>
          <Button variant="link">Manage User</Button>
        </Link>
      )}
    </>
  );
};

const PrintQuestionnaire = ({
  type,
  reports,
}: {
  type: "alsfrs" | "alssq";
  reports: Reports;
}) => {
  const scores: { key: string; score: number }[] = [];

  map(reports, (value, key) => {
    const score = value?.[type]?.score;
    if (score !== undefined) {
      scores.push({ key, score });
    }
  });

  return (
    <Stack spacing={0}>
      <Heading size="md">
        {type === "alsfrs" ? "ALSFRS" : "ALSSQ"} Scores
      </Heading>
      {scores.length > 0 ? (
        <List>
          {scores.map(({ key, score }) => {
            return (
              <ListItem key={key}>
                {formatMonth(key)}: {score}/
                {maxQuestionnaireScore(type === "alsfrs" ? alsfrs : alssq)}
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Text>No records.</Text>
      )}
    </Stack>
  );
};

const Stool = ({ reports }: { reports: Reports }) => {
  const data = useMemo(() => {
    const data: {
      key: string;
      stool: Partial<StoolQuestionnaire["stool"]>;
    }[] = [];
    map(reports, (value, key) => {
      const stool = value?.stool?.stool;
      if (stool?.complete) {
        data.push({
          key,
          stool,
        });
      }
    });

    return data;
  }, [reports]);

  return (
    <Stack spacing={4}>
      <Stack>
        <Heading size="md">Stool</Heading>
        <TableContainer>
          <Table size="sm" variant="unstyled">
            <Thead>
              <Tr>
                <Th>Month</Th>
                <Th>Type</Th>
                <Th>Color</Th>
                <Th>Odor</Th>
                <Th>Volume</Th>
                <Th>Floats?</Th>
                <Th>Frequency</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map(({ key, stool }) => {
                return (
                  <Tr key={key}>
                    <Td>{formatMonth(key)}</Td>
                    <Td>
                      {stool.type !== undefined ? stoolTypes2[stool.type] : ""}
                    </Td>
                    <Td>{stool.color ? startCase(stool.color) : ""}</Td>
                    <Td>{stool.odor ? startCase(stool.odor) : ""}</Td>
                    <Td>
                      {stool.volume !== undefined
                        ? stoolVolumes[stool.volume]
                        : ""}
                    </Td>
                    <Td>
                      {stool.float === undefined
                        ? ""
                        : stool.float
                        ? "Yes"
                        : "No"}
                    </Td>
                    <Td>
                      {stool.daily === undefined
                        ? ""
                        : stool.daily
                        ? `${stool.times}/day`
                        : `${stool.times}/week`}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      <Stack>
        <Heading size="md">GI Symptoms</Heading>
        <TableContainer>
          <Table size="sm" variant="unstyled">
            <Thead>
              <Tr>
                <Th>Month</Th>
                <Th>Symptoms</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map(({ stool, key }) => {
                return (
                  <Tr key={key}>
                    <Td>{formatMonth(key)}</Td>
                    <Td>
                      {stool.giSymptoms?.length
                        ? stool.giSymptoms.map((s) => giSymptoms[s]).join(", ")
                        : ""}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

const Urine = ({ reports }: { reports: Reports }) => {
  const data = useMemo(() => {
    const data: {
      key: string;
      urine: Partial<StoolQuestionnaire["urine"]>;
    }[] = [];
    map(reports, (value, key) => {
      const urine = value?.stool?.urine;
      if (urine?.complete) {
        data.push({
          key,
          urine,
        });
      }
    });

    return data;
  }, [reports]);

  return (
    <Stack>
      <Heading size="md">Urine</Heading>
      <TableContainer>
        <Table size="sm" variant="unstyled">
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th>Frequency</Th>
              <Th>Color</Th>
              <Th>Odor</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map(({ key, urine }) => {
              return (
                <Tr key={key}>
                  <Td>{formatMonth(key)}</Td>
                  <Td>
                    {urine.timesDaily === undefined
                      ? ""
                      : `${urine.timesDaily}/day`}
                  </Td>
                  <Td>{urine.color ? urineColors[urine.color] : ""}</Td>
                  <Td>{urine.odor ? startCase(urine.odor) : ""}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const PrintRegimens = ({
  list,
  title,
  regimens: _regimens,
}: {
  list: string;
  title: string;
  regimens: Regimen[];
}) => {
  const config = useConfigList(list);
  const regimens = _regimens.filter((r) => r.list === list);

  return (
    <Stack spacing={0}>
      <Heading size="md">{title}</Heading>
      {regimens.length === 0 ? (
        <Text>No records.</Text>
      ) : (
        <List>
          {orderBy(
            regimens,
            (r) => {
              return config[r.primaryId];
            },
            "asc"
          ).map((regimen) => {
            return (
              <ListItem key={regimen.id}>
                {config[regimen.primaryId]}:{" "}
                {orderBy(regimen.usages, "startedAt", "desc")
                  .map((u) => {
                    return `${formatMoment(moment(u.startDate))}
                    ${u.percentage ? ` ${percentages[u.percentage]}` : ""}${
                      u.amount && u.amountUnit
                        ? ` ${u.amount}${amountUnits[u.amountUnit]}`
                        : ""
                    }${
                      u.frequency && u.frequencyUnit
                        ? ` ${u.frequency}x/${frequencyUnits[u.frequencyUnit]}`
                        : ""
                    }${
                      u.duration && u.durationUnit
                        ? ` ${u.duration} ${durationUnits[u.durationUnit]}`
                        : ""
                    }${u.method ? ` (${methods[u.method]})` : ""}`;
                  })
                  .join(", ")}
              </ListItem>
            );
          })}
        </List>
      )}
    </Stack>
  );
};

const PrintSupport = ({ reports: _reports }: { reports: Reports }) => {
  const reports = chain(_reports)
    .map((report, id) => ({ id, ...report }))
    .orderBy("id", "desc")
    .value();
  return (
    <Stack>
      <Heading fontSize="md">Family & Medical Support</Heading>
      <Stack spacing={2}>
        {reports.map((report) => {
          const people = report.emotionalSupportPeople || [];
          const doctorIds = keys(report.medicalSupport).filter(
            (doctorId) => !!report.medicalSupport?.[doctorId]
          );
          if (people.length === 0 && doctorIds.length === 0) return null;

          return (
            <Stack spacing={2} key={report.id}>
              <Heading fontSize="md">{report.id}</Heading>
              {people.length > 0 && (
                <Stack spacing={1}>
                  <Heading fontSize="sm">Emotional & Physical Support</Heading>
                  <FamilySupportTable people={people} />
                </Stack>
              )}
              {doctorIds.length > 0 && (
                <Stack spacing={1}>
                  <Heading fontSize="sm">Medical Support</Heading>
                  <MedicalSupportTable
                    medicalSupport={report.medicalSupport || {}}
                  />
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
