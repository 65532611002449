import { Box, Stack } from '@chakra-ui/react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import useBrands from '../hooks/useBrands'
import useConfigList from '../hooks/useConfigList'
import Regimens from './Regimens'
import useNavigateNext from '../hooks/useNavigateNext'

export default () => {
  const items = useConfigList('supplements')
  const brands = useBrands()
  const navNext = useNavigateNext()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    navNext()
  }

  const lists = [
    {
      fieldId: 'supplementId',
      label: 'Supplement',
      items,
      id: 'supplements',
    },
    {
      fieldId: 'brandId',
      label: 'Brand',
      items: brands,
      id: 'brands',
    },
  ]

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <MonthlyReportHeader title="Supplements" />
        </Box>
      </form>
      <Regimens
        recordLabel="Supplement"
        amountEnabled
        frequencyEnabled
        methodEnabled
        lists={lists}
      />
    </Stack>
  )
}
