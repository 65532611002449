import { Box, Stack } from '@chakra-ui/react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import useBrands from '../hooks/useBrands'
import useConfigList from '../hooks/useConfigList'
import useNavigateNext from '../hooks/useNavigateNext'
import Regimens from './Regimens'

export default () => {
  const items = useConfigList('nonSupplementMedications')
  const brands = useBrands()
  const navNext = useNavigateNext()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    navNext()
  }

  const lists = [
    {
      fieldId: 'nonSupplementMedicationId',
      label: 'Medication',
      items,
      id: 'nonSupplementMedications',
    },
    {
      fieldId: 'brandId',
      label: 'Brand',
      items: brands,
      id: 'brands',
    },
  ]

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <MonthlyReportHeader title="Non-Supplement Medications" />
        </Box>
      </form>
      <Regimens
        recordLabel="Medication"
        amountEnabled
        frequencyEnabled
        methodEnabled
        lists={lists}
        alert="Please include ALL medications that you are taking, even if they are not for ALS."
      />
    </Stack>
  )
}
