import { Stack, Box } from '@chakra-ui/react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import useConfigList from '../hooks/useConfigList'
import useNavigateNext from '../hooks/useNavigateNext'
import Regimens from './Regimens'

export default () => {
  const items = useConfigList('mentalStrategies')
  const navNext = useNavigateNext()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    navNext()
  }

  const lists = [
    {
      fieldId: 'mentalStrategyId',
      label: 'Strategy',
      items,
      id: 'mentalStrategies',
    },
  ]

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <MonthlyReportHeader title="Mind Strategies" />
        </Box>
      </form>
      <Regimens
        recordLabel="Mind Strategy"
        durationEnabled
        frequencyEnabled
        lists={lists}
      />
    </Stack>
  )
}
